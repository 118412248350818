













































































import Vue from 'vue'
export default Vue.extend({
  name: 'app',
  components: {
  },
  data: () => ({
    drawer: false
  })
})
