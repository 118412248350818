import '@babel/polyfill'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  options: {
    customProperties: false
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#29496B',
        secondary: '#347CAC',
        accent: '#C90049',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        PortfolioGroup: '#29496B'
      }/*,
      light: {
        primary: colors.blue.darken1,
        secondary: colors.purple.darken1,
        accent: colors.red.lighten1,
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        PortfolioGroup: colors.shades.white,
        PortfolioGroupDetail: colors.shades.black
      } */
    }
  }
})
