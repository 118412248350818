



















import Vue from 'vue'
import PSection from '../components/PSection.vue'

export default Vue.extend({
  name: 'Portfolio',
  props: {
    gigs: {
      type: Array,
      required: true,
      default: () =>
        [
          {
            title: 'An error occured',
            subtitle: 'The data did not load from the route',
            projects: []
          }
        ]
    }
  },
  components: {
    PSection
  }
})
